export const partners = [
  {
    id: 1,
    image: require("../assets/images/logos-5.png"),
    link: "https://fdrs-ltd.com",
  },
  {
    id: 2,
    image: require("../assets/images/logos-6.png"),
    link: "https://container-xchange.com/",
  },
  {
    id: 3,
    image: require("../assets/images/logos-7.png"),
    link: "https://zybotech.com",
  },
  {
    id: 4,
    image: require("../assets/images/logos-8.png"),
    link: "https://inspexion.com/",
  },
  // {
  //   id: 6,
  //   image: require("../assets/images/RatesLand2.jpg"),
  //   link: "https://ratesland.com",
  // },
  {
    id: 7,
    image: require("../assets/images/shipnet.png"),
    link: "https://shipnext.com/",
  },
  {
    id: 8,
    image: require("../assets/images/CargoWise2.png"),
    link: "https://www.cargowise.com",
  },
  {
    id: 9,
    image: require("../assets/images/hexcore.png"),
    link: "http://www.hexcore.ai/",
  },
  {
    id: 10,
    image: require("../assets/images/kolyns.png"),
  },
];

export const membersOf = [
  {
    id: 3,
    image: require("../assets/images/home/fiata-wbg.png"),
    link: "https://fiata.org/",
  },
  {
    id: 1,
    image: require("../assets/images/home/itc-wbg.png"),
    link: "https://www.ciffa.com",
  },
  {
    id: 2,
    image: require("../assets/images/home/ciffa-wbg.png"),
    link: "https://thetradecouncil.com",
  },
];

export const testimonials = [
  {
    comment:
      "It was an honor to have met you again as well as all representatives from various companies and their colleagues. Our attendance will not have been possible without support from yourself and Sam. We are grateful. We are happy to have made instant connections and requests upon some of our meetings and we believe it will continue in same vain.",
    author: "Millicent Kumordzi",
    designation: "Administrator - Three Port Logistics",
    logo: require("../assets/images/threeport.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
  },
  {
    comment:
      "It was indeed a well-organized event with select partners from all over the world. The boat trip and the historic Sait Halim Paşa Manison were particularly and truly remarkable. The meetings and the social events contributed to the closer relations and cooperation in between the members and your great network management team. It was a pleasure to be with you, your family, and the members in Istanbul, thank you for your great work for us all.",
    author: "Turgut ERKESKIN",
    designation: "President & CEO - Genel Transport ",
    logo: require("../assets/images/genel.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
  },
  {
    comment:
      "It was my first experience but you and your team made me comfortable and made me feel the part of the network. It was for me a very memorable and learning experience to be part of such a event , thank you for the same. I am excited to work with the partners and participants of the meet and develop business for mutual benefits. A special thank you to Agnes and Sam for taking so much personalized care and for making a first-time participant like me at home and comfortable. I look forward to working with the Pro Network and for the second edition in Prague. Finally, I can say there will be many such meets I will attend thanks to the Experience of this one but the first one I will cherish forever. Thank you and Wishing Pro a very successful networking for the future,",
    author: "Gulnar Sayed",
    designation: "General Manager - Circle Logistics India Pvt. Ltd ",
    logo: require("../assets/images/circle.png"),
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment:
      "It was my pleasure and honour to attend the first valuable and exciting event. I prefer and fully agree with your philosophy having only a limited partner per countries, less is more and at the end it is people's business. We all know that too many cooks spoil the broth. Keep on going and lets all share the spirit.",
    author: "Stefan Heine",
    designation:
      "Operations Manager - Geuther and Schnitger Logistics GmbH & Co. KG ",
    logo: require("../assets/images/guether.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0e/Flag_of_Bremen.svg",
  },
  {
    comment:
      "I had a wonderful time attending the conference and connecting with all the members. The meetings were very intimate and the conversations were very relaxed. The disarming and friendly atmosphere made it easy to discuss business and share thoughts on different ideas and possibilities. The professional and prompt response from prolog team was also very commendable. Looking forward to the next conference.",
    author: "Mustafa Akram",
    designation: "MANAGEMENT - RTW Shipping & Logistics Limited ",
    logo: require("../assets/images/rtw.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
  },
  {
    comment:
      "Each word rising from heart falls down in the heart. Thanks for sharing your emotion and your logic with me, with us. Being a member of Pro is now a pleasure, after all that Daddy Ganga provided us with. the first time of my being in Istanbul coincided with the first time of meeting pro family created unforgettable memory and fantastic experience for me. I do appreciate for being the most important part of this memory and experience.",
    author: "Fatemeh Yahya",
    designation: "Overseas | Mehan Tondar Bar ",
    logo: require("../assets/images/mehan.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
  },
  {
    comment:
      "We would like to express our gratitude to the entire Prolog team for organizing the conference at the highest level. New opportunities, acquaintances, communication, knowledge, developing ideas and just a good time - all this was received by each participant of the conference in 2 days. Prolog is not just a 'business' it is a big family united by a common goal and interests. Looking forward to the next conference.",
    author: "Nicolas Vandamme",
    designation: "Managing Director - V&S FREIGHT MANAGEMENT BV ",
    logo: require("../assets/images/vands.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
  },
  {
    comment:
      "It is good to see this flowering. Congratulations on a job well done. Great job on the pay protects and payment system.",
    author: "Ashok Thomas",
    designation: "President - Global Supply Chain Logistics Inc ",
    logo: require("../assets/images/global.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg",
  },
  {
    comment:
      "hanks for all you did before & during this great conference & gathering which Prolog had for 1st time! Surely each year it will be better than last one!",
    author: "Soheil Alimohammadi",
    designation: "General Manager - Mehan Tondar Bar ",
    logo: require("../assets/images/mehan.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
  },
  {
    comment:
      "Indeed, our pleasure to be part of the maiden event. Go Pro and the network will definitely shape up to a mature platform with great initiatives and uniqueness. Wish you and the team all the best.",
    author: "Biju Sivakumar",
    designation: "Joint Managing Director & COO Navio Shipping LLC ",
    logo: require("../assets/images/navio.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
  },
  {
    comment:
      "It was a really high quality, friendly environment, we enjoyed it immensely. Thank you and your entire team for making us experience this beautiful union. GO PRO!!",
    author: "Cuneyt EKENER",
    designation: "GENEL TRANSPORT ",
    logo: require("../assets/images/genel.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
  },
  {
    comment:
      "I must say I was very impressed with the professionalism showed in organising the event and the quality of the participants. We look forward to be part of the Pro network and I am sure it will grow into one of the best and biggest network in the near future, Well done and keep growing !!",
    author: "Sheetal Shetty",
    designation: "Group Managing Director - Capricorn Logistics Pvt. Ltd ",
    logo: require("../assets/images/capricorn.png"),
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment:
      "I think I am not the only one who felt your sincerity, friendship, potential to give to the network but everybody those were together with us during the event. It was a real pleasure meeting everyone in first PRO Networking Conference.",
    author: "Hakan CAMAN",
    designation: "Airfreight Manager - GENEL TRANSPORT ",
    logo: require("../assets/images/genel.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
  },
  {
    comment:
      "It was a pleasure and an honour to attend the first ever Pro conference. Thank you once again for bringing the Pro family together and look forward to the next! The success of this event will be remembered as Pro network grows from strength to strength.",
    author: "Asitha Udawela",
    designation: "Managing Director - Logenix Logistics India Private Limited ",
    logo: require("../assets/images/logenix.png"),
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment:
      "I'd like to thank PRO LOG management for the amazing event in this professional level Really will never forget the Amazing Bosphorus trip along with the dinner in the lovely Palace. Also, really it was a great chance to meet the awesome members Face to Face. Waiting more business cooperation in the future",
    author: "Saikat Alam Saif",
    designation: "Coordinator-Business Development - Marvel Freight Ltd.",
    logo: require("../assets/images/marvel.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
  },
  {
    comment:
      "Thank you for all your efforts to hold such a brilliant event at Istanbul. It was our pleasure to be an attendee there. Despite being the first event of Prolog, Istanbul conference paved the way for us to establish friendly business relationships with the most professional partners attended at that event. We met with really well experienced and professional partners and we hope it will lead to golden mutual cooperative opportunities. Now we have marked our schedule for coming Pro conference in Prague and we hope we can attend there and meet with more members of this Pro Family. Thanks for all you did, all your supports and all that you are doing.",
    author: "Sogand Banihashemi",
    designation: "CEO Manager - Mehan Tondar Bar ",
    logo: require("../assets/images/mehan.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
  },
  {
    comment:
      "I would like to thank you all for the kind hospitality and efforts during conference. All were fantastic and very funny.",
    author: "Doganay SAYGILI",
    designation: "Mersin Branch Manager - GENEL TRANSPORT ",
    logo: require("../assets/images/genel.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
  },
  {
    comment:
      "All round great event. The hotel was great. The meeting facilities good. The events were exceptional. The dinner at the castle and the transport to the event was very well done.",
    author: "Ashok Thomas",
    designation: "President - Global Supply Chain Logistics Inc ",
    logo: require("../assets/images/global.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg",
  },
  {
    comment:
      "Prolog is a young network but the way they handled and organised the event it was very professional and focused majorily on member to member engagement. The Pro Team has made all efforts to design and execute best networking experience for members. I wish Prolog more success & look forward to more such networking events.",
    author: "Gopi Nair",
    designation: "CEO Shiftco Shipping and Logistics India Pvt. Ltd",
    logo: require("../assets/images/Shiftco.png"),
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment:
      "This was my first conference with Prolog and I can say it was AMAZING. The Staff was so friendly and helped us to resolve any issue we might have. The way everything was conducted, it was SUPERB. | think I never had a Network that went above and beyond for the delegates. Thank you very much for this wonderful experience.",
    author: "Michelle Castillo",
    designation: "Network Development Manager Navia Freight",
    logo: require("../assets/images/navia.jpg"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Australia.svg/1200px-Flag_of_Australia.svg.png?20211007161007",
  },
  {
    comment:
      "We all had a good time in Prague. Made good connections. Met with very genuine people. It was well organized event. Kudos to the Prolog team. Also, we send recommendations to partners to join Prolog.",
    author: "Pratik Dharia",
    designation: "Senior Director Sobel Network Shipping Co., Inc.",
    logo: require("../assets/images/Sobel-Logo-Color.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Flag_of_the_United_States_of_America_%28American_Legion%29.jpg/1200px-Flag_of_the_United_States_of_America_%28American_Legion%29.jpg?20230218015459",
  },
  {
    comment: "Great time together.... Wonderful arrangements and meetings",
    author: "Biju Sivakumar",
    designation: "Joint Managing Director & COO Navio Shipping LLC ",
    logo: require("../assets/images/navio.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
  },
  {
    comment:
      "First, thank you for your kind approach and for giving us the opportunity to meet our business partners. This year was our first year at the Prolog and we thank you for giving us a really good conference. We hope to see you again soon. Team Prolog thank you for this great organisation, for the perfect support before, during and after the conference, great job it was a pleasure for us to meet all of you, getting new friends hope to see you at the next conference or earlier anywhere at the world.",
    author: " Elif Balci",
    designation: "General Manager of IFF (International Freight Forwarding)",
    logo: require("../assets/images/iff.jpg"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
  },
  {
    comment:
      "It was a great time with your members. It was a very good arrangement.",
    author: "Techinee Akkaragumtorn",
    designation: "Manager Director Full Well Freight (Thailand) Co,. Ltd.",
    logo: require("../assets/images/full well.jpg"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/1200px-Flag_of_Thailand.svg.png?20180215104633",
  },
  {
    comment:
      "It was a great pleasure to meet you in Prague. I can't wait to see all the new updates in the pro-network. Hope to see you again in our 3rd Conference.",
    author: "Hanane ENNACIRI",
    designation: "Sales Manager AZ Trans",
    logo: require("../assets/images/Aztrans.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Morocco_unbordered.svg/1200px-Flag_of_Morocco_unbordered.svg.png?20200213174131",
  },
  {
    comment: "It was an amazing event!",
    author: "Babu Ramesh",
    designation: "Director Krishko Logistics India Pvt Ltd",
    logo: require("../assets/images/krishko.jpg"),
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
  },
  {
    comment:
      "It was a pleasure to meet you all. Hope we will manage to build up a strong connection after this awesome meeting. Let's get ready for next year, so we can multiply our network at least x2. Let's stay in touch.",
    author: "Oleksandr Horpynchenko",
    designation: "CEO Logistics-Partner LLC",
    logo: require("../assets/images/lp.png"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/1200px-Flag_of_Ukraine.svg.png?20230624202942",
  },
  {
    comment: "It was nice meeting you all, it was an incredible experience",
    author: "Michela Verri",
    designation: "Cigisped SRL (Sea Dept)",
    logo: require("../assets/images/cigisped.jpg"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/03/Flag_of_Italy.svg/1200px-Flag_of_Italy.svg.png?20160518044416",
  },
  {
    comment:
      "To be perfectly honest, Scott & I did have our concerns about the network with being just 2 yrs. old but this quickly quashed as soon as we walked into the hotel and was greeted by your welcoming team. This continued into Friday starting with your presentations (Sam's especially) and finishing with the amazing Gala evening. Saying that, 'presentations' and 'gala evenings' only make up a small part of the conference from Allseas perspective. The most important and deciding factor for Allseas would be the quality of the knowledge the delegates and we were not disappointed. Of course, we already",
    author: "Steve Morris",
    designation: "Import Branch Manager Allseas Global Logistics",
    logo: require("../assets/images/allseas.svg"),
    flag: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Flag_of_the_United_Kingdom_%281-2%29.svg/1200px-Flag_of_the_United_Kingdom_%281-2%29.svg.png?20190916111110",
  },
];

export const aboutUs = [
  {
    id: 1,
    title: "About Us",
    subtitle: (
      <div style={{ lineHeight: 1.5 }}>
        <p>
          Professional Logistics Network (ProLog) stands out as a neutrally
          managed global network comprising independent freight forwarders and
          logistics providers. Beyond the standard benefits typical of logistics
          networks, such as financial protection, member-to-member instant
          payment, insurance products, and top-tier customer support, ProLog
          offers a range of unique features and benefits:
        </p>
      </div>
    ),
    image: require("../assets/images/touch.png"),
  },
  {
    id: 2,
    title: "What sets us apart",
    subtitle: (
      <div style={{ lineHeight: 1.5 }}>
        <p>
          What sets us apart is our innovative approach to networking, which has
          redefined networking. Here are the key features that set us apart:
        </p>
      </div>
    ),
    image: require("../assets/images/about/apart.png"),
  },

  {
    id: 3,
    title: "What do you get?",
    subtitle:
      "You can immediately communicate with fellow members through our portal itself - submit enquiries, receive inquiries, know what is floating around. No longer do you need to deal with window shoppers cluttering your inbox. You can now pick and choose from the series of serious inquiries that you can find on our portal.",
    image: require("../assets/images/about/you-get.png"),
  },
  {
    id: 4,
    title: "What's in it for us?",
    subtitle:
      "Nothing… your growth is our growth. Your success - ours.  It is all included when you go for your PRO badge*.",
    image: require("../assets/images/about/for-us.png"),
  },
  {
    id: 5,
    title: "What's next?",
    subtitle:
      "The highlight: very soon you can convert your RFQ into a firm quote and use our portal to send and receive payments too. But that's the future we are the future.",
    image: require("../assets/images/about/next.png"),
  },
  {
    id: 6,
    title: "The power is in the number",
    subtitle:
      "With a potential 14000 forwarders lined up to sign up - we are ensuring that only the best of this 14000 get to the Partner level. We want the best to partner with the best. But there is a limit to how many can earn a PRO badge from every city. Our stringent vetting process assures that only the true professional becomes eligible to earn the PRO badge. We make sure that you have multiple options to select from globally. ProLog believes in the collective power and dedicatedly serves its members towards growth and success.",
    image: require("../assets/images/about/power.png"),
  },
];

export const conference = [
  {
    id: 2,
    title: "Second Pro Networking Conference",
    img: require("../assets/images/first_confer2.jpg"),
  },
  {
    id: 1,
    title: "First Pro Networking Conference",
    img: require("../assets/images/first_confer1.jpg"),
  },
];

export const secondConference = [
  { id: 1, name: "1-1 Meetings", preview: "" },
  { id: 2, name: "Group Photo", preview: "" },
  { id: 3, name: "Lunch", preview: "" },
  { id: 4, name: "Opening Ceremony", preview: "" },
  { id: 5, name: "Registeration", preview: "" },
  { id: 6, name: "Staff", preview: "" },
  { id: 7, name: "Troja", preview: "" },
  { id: 8, name: "U Fleku", preview: "" },
  { id: 9, name: "Welcome Cocktail", preview: "" },
];

export const proAdvant = [
  {
    id: 1,
    redirect: "semi-exclusive",
    title: "Semi Exclusive",
    description:
      "We understand the need to have choices. With a few options you are assured that you can align with the right partner with the same mindset and ethics like yourself. Being a Semi-Exclusive network ensures that you get to choose from amongst the best. With a transparent view of your partners’ certifications and their expiry, you know the expertise you are tying up with. Similarly anyone searching for a forwarder in your domain is assured that he is partnering with the best.",
    img: require("../assets/images/home/semi-exclusive.png"),
  },
  {
    id: 2,
    redirect: "financial",
    title: "Financial Protection",
    description:
      "Work in a safe and secure environment where your transactions between PRO members are protected. Your membership comes preloaded with Pro-shield – our top of the line financial protection program at no additional cost to you. On par with the best in the industry, when you bear this mark and we bear your risk.",
    img: require("../assets/images/home/financial.png"),
  },
  {
    id: 3,
    redirect: "mobile-app",
    title: "Mobile App",
    description:
      "In tune with the times, and allowing you to be more efficient than before, our Mobile App packs the most powerful features of our state of the art dashboard into your handheld. With the mobile app you can now perform smart search, view and respond to inquiries and also raise new inquiries. Experience the power of true networking with our app available on the Google Play Store and the Apple App Store.",
    img: require("../assets/images/home/mobile.png"),
  },
  {
    id: 4,
    redirect: "strategic-partners",
    title: "Access to top class strategic partners",
    description:
      "Everything you need to know about your partner is right where you want it to be – on one screen. As a Pro member you can view your partner’s profile, specialty, service, contacts, accreditation, certifications, branches and even reach out to them with just a click of your mouse. What better way to network than this.",
    img: require("../assets/images/home/strategic-partner.png"),
  },
  {
    id: 5,
    redirect: "social-media",
    title: "Complete social media exposure for maximum visibility",
    description:
      "Why do you join a network? To network, to be visible and that’s exactly what we help you achieve. Our social media is your podium. We are happy to announce all your achievements… right from your enrolment with us and then we repost this onto the several groups we are part of. We also help you with an email blast that announces your arrival with a bang across the tens of thousands of forwarders across the globe.",
    img: require("../assets/images/home/social-media.png"),
  },
  {
    id: 6,
    redirect: "networking",
    title: "Networking right from the first month of membership",
    description:
      "When you join Professional Logistics Network, you are joining a network that does what the name suggests Professionally helps you Network. You are invited to join our monthly mini networking webinar where you get to introduce your company, meet with fellow forwarders from across the globe 1-1 in our own version of networking. You start networking right from the word Go Unbelievable, but true. This has proved to be a great success and you have to be a part of it to experience it.",
    img: require("../assets/images/home/networking.png"),
  },
  {
    id: 7,
    redirect: "support-device",
    title: "Round the clock support service",
    description:
      "With support centers across timezones, we are able to provide you with the much required and timely support as you need it and when you need it. We are available round the clock, even on holidays. You can reach us directly onto our Whatsapp right from the website. No more waiting for interactive chats with a bot.. you will be connected a human who will respond to you in real time. Now that’s true support!!!",
    img: require("../assets/images/home/support.png"),
  },
  {
    id: 8,
    redirect: "member-to-member",
    title: "Member to member payment system",
    description:
      "PRO-Pay – our unparalleled premium member to member payment system with less or no transaction fees is a unique feature that saves you thousands of dollars annually just by saving on banking fees. Every penny saved is a penny earned in the true spirit. With multi factor authentications, Pro Pay ensures that your money is safe until you authorize it to be transacted.",
    img: require("../assets/images/home/member-to-member.png"),
  },
  {
    id: 9,
    redirect: "many-more",
    title: "and many more...",
    description:
      "Strategic Partnerships with Cargowise, Zybotech, InspeXion, Xchange containers, Shipnext, Ratesland Annual conferences in top class venues and 5 star properties Self serve portal permitting you to upload your documents and download your membership certificates and Personalised PRO Logo Constantly evolving features and benefits…",
    img: require("../assets/images/home/many-more.png"),
  },
];

export const AboutUsBulletPoints = [
  {
    id: 1,
    heading: "Completely Menuless State-of-the-Art Dashboard:",
    text: "ProLog boasts a cutting-edge dashboard design that eliminates traditional menu structures for a seamless and intuitive user experience.",
    img: require("../assets/images/about/dashboard.png"),
  },
  {
    id: 2,
    heading: "Smart Search and Smart RFQ:",
    text: " The network provides advanced search capabilities and request for quotation functionalities, enhancing efficiency and accuracy in sourcing logistics solutions.",
    img: require("../assets/images/about/fresco.png"),
  },
  {
    id: 3,
    heading: "Who Viewed My Profile:",
    text: "This feature facilitates business lead generation by allowing members to track who has viewed their profiles within the network.",
    img: require("../assets/images/about/cowboy.png"),
  },
  {
    id: 4,
    heading: "Mobile App:",
    text: "ProLog offers a mobile application, enabling members to access network features and services on the go, enhancing convenience and accessibility.",
    img: require("../assets/images/about/mobile.png"),
  },
  {
    id: 5,
    heading: "Semi-Exclusive Network:",
    text: "Membership in ProLog offers access to a semi-exclusive network, fostering deeper connections and collaborations among members.",
    img: require("../assets/images/about/network.png"),
  },
  {
    id: 6,
    heading: "Monthly Networking Webinars:",
    text: "Members benefit from monthly networking webinars, facilitating networking opportunities right from the first month of membership.",
    img: require("../assets/images/about/elearning.png"),
  },
  {
    id: 7,
    heading: "Loyalty Points and Rewards System:",
    text: "ProLog implements a loyalty program with points and rewards, incentivizing member engagement and participation.",
    img: require("../assets/images/about/reward.png"),
  },
  {
    id: 8,
    heading: "Complete Social Media Coverage:",
    text: "The network ensures comprehensive social media coverage, amplifying members' visibility and reach across digital platforms.",
    img: require("../assets/images/about/wifi.png"),
  },
];

export const SetUsApartBulletPoints = [
  {
    id: 1,
    heading: "Menuless Dashboard:",
    text: "Our dashboard eschews traditional menu structures, offering a seamless and intuitive user experience without the clutter of navigation menus.",
    img: require("../assets/images/about/dashboard.png"),
  },
  {
    id: 2,
    heading: "Visually Enticing Screens:",
    text: "We prioritize aesthetics, providing visually engaging screens that captivate users and enhance their interaction with the platform.",
    img: require("../assets/images/about/binary-code.png"),
  },
  {
    id: 3,
    heading: "Simple Interface:",
    text: "Our interface is designed for ease of use, making it straightforward for users to understand and navigate the platform without the need for extensive training or technical expertise.",
    img: require("../assets/images/about/banner.png"),
  },
  {
    id: 4,
    heading: "High-Level Security and Privacy:",
    text: "Security and privacy are paramount in our network, with robust measures in place to safeguard sensitive data and ensure user confidentiality.",
    img: require("../assets/images/about/lock.png"),
  },
  {
    id: 5,
    heading: "Hi-Tech Back End:",
    text: "Behind the scenes, our network boasts a state-of-the-art backend infrastructure, leveraging cutting-edge technology to deliver reliable performance and scalability.",
    img: require("../assets/images/about/dashboard.png"),
  },
];
