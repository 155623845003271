import { Backdrop, Checkbox, Drawer, useMediaQuery } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CSSLink from "../components/CSSLink";
import Header from "../components/Header";
import { BASE_URL } from "../constants/config";
import Menu from "./Menu";
import Footer from "../components/Footer";

const Gallery2 = () => {
  const { id, conferenceId } = useParams();
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);

  const [conferenceOne, setConferenceOne] = useState([]);
  const [conferenceTwo, setconferenceTwo] = useState([]);

  const secret = "6f6ebbcf5833eef9";
  const apiKey = "63efaf4cec8ea9df12d896f45de7e5fd";
  const userId = "198996610@N05";
  const albumId = parseInt(id);
  const flickrUrl = `https://api.flickr.com/services/rest/?method=flickr.photosets.getPhotos&api_key=${apiKey}&photoset_id=${id}&user_id=${userId}&format=json&nojsoncallback=1`;

  const ConferenceOne = () => {
    setloading(true);
    axios
      .get(`${BASE_URL}/api/gallery/get_all_gallery`)
      .then((res) => {
        setConferenceOne(res.data.albums);
        setloading(false);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  };

  const ConferenceTwo = () => {
    setloading(true);
    axios
      .get(flickrUrl)
      .then((response) => {
        const data = response.data;

        const photosData = data.photoset.photo.map((photo) => ({
          id: photo.id,
          title: photo.title,
          imageUrl: `https://live.staticflickr.com/${photo.server}/${photo.id}_${photo.secret}_z.jpg`,
        }));

        setconferenceTwo(photosData);
        setloading(false);
      })
      .catch((error) => {
        console.error("Error fetching Flickr data:", error);
        setloading(false);
      });
  };

  useEffect(() => {
    if (parseInt(conferenceId) === 1) {
      ConferenceOne();
    } else if (parseInt(conferenceId) === 2) {
      ConferenceTwo();
    }
  }, []);

  return (
    <section>
      <CSSLink />
      <Header />
      <div class="page-12">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            src={require("../assets/images/loader.gif")}
            style={{ height: "175px", width: "175px" }}
          />
        </Backdrop>

        <main>
          <div class="contents">
            <div class="home" style={{ paddingTop: "50px" }}>
              <span class="material-icons"></span>
              <p onClick={() => navigate("/")}></p>
            </div>

            <div class="content" style={{ flexDirection: "column" }}>
              {/* Conference 1 */}
              {parseInt(conferenceId) === 1 &&
                conferenceOne.map((confer, index) => {
                  return (
                    confer.id === parseInt(id) && (
                      <div
                        key={index}
                        class="row-1"
                        style={{
                          display: "grid",
                          gridTemplateColumns:
                            "repeat(auto-fit, minmax(250px, 1fr))",
                          gap: "20px",
                        }}
                      >
                        {confer?.pictures?.map((m, ind) => {
                          return (
                            <div
                              class="card"
                              key={ind}
                              style={{
                                height: "270px",
                                width: "250px",
                                backgroundColor: "transparent",
                              }}
                            >
                              <img
                                src={m}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )
                  );
                })}
              {/* Conference 2 */}
              {parseInt(conferenceId) === 2 && (
                <div
                  class="row-1"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                    gap: "20px",
                  }}
                >
                  {conferenceTwo.map((photo, ind) => (
                    <div
                      class="card"
                      key={ind}
                      style={{
                        height: "270px",
                        width: "250px",
                        backgroundColor: "transparent",
                      }}
                    >
                      <img
                        key={photo.id}
                        src={photo.imageUrl}
                        alt={photo.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(
                            `https://www.flickr.com/photos/198996610@N05/${photo.id}/in/album-72177720310780652/`,
                            "_blank"
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </main>
      </div>

      <Footer />
    </section>
  );
};

export default Gallery2;
