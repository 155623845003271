/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { staging, prod, redirect } from "../constants/config";
import CSSLink from "./CSSLink";
import GoogleTranslate from "./GoogleTranslate";

const Header = ({ open, setOpen, main, position }) => {
  const navigate = useNavigate();

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const largest = useMediaQuery("(min-width:1200px)");
  const mobile = useMediaQuery("(max-width:600px)");

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <CSSLink /> */}
      {/* <header
        style={{
          height: main ? "" : "",
          backgroundColor: scrolled ? "#01041e" : "transparent",
          padding: "0rem 0rem",
          height: "7.5%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          paddingTop: !scrolled && "20px",
        }}
        className={scrolled ? "sticky" : ""}
      >
        <img
          className="nav-menu"
          src={require("../assets/images/bx-align-middle.png")}
          alt=""
          data-aos="fade-up"
          data-aos-offset="50"
          onClick={() => {
            setOpen(!open);
          }}
          style={{
            height: scrolled ? (isMobile ? "20px" : "30%") : "25px",
            width: scrolled ? (isMobile ? "20px" : "1.5%") : "25px",
            paddingTop: isMobile ? "20px" : "0px",
            // margin: scrolled && "0px",
            // marginRight: isMobile && "12px",
          }}
        />
        <div
          className="logos"
          data-aos="fade-down"
          data-aos-offset="100"
          style={{ width: scrolled ? "60%" : "" }}
        >
          {!scrolled && (
            <img
              className="logo-bg"
              src={require("../assets/images/Component1.png")}
              alt=""
              style={{
                height: isMobile ? "0%" : !isTablet ? "190px" : "175px",
                width: isMobile ? "0%" : !isTablet ? "1000px" : "750px",
              }}
            />
          )}
          <img
            className="logo"
            src={require("../assets/images/logo-new.png")}
            alt=""
            onClick={() => navigate("/")}
            style={{
              height: scrolled
                ? isMobile
                  ? "65px"
                  : !isTablet
                  ? "62.5px"
                  : null
                : "100px",
              width: scrolled
                ? isMobile
                  ? "65px"
                  : !isTablet
                  ? "65px"
                  : "87px"
                : "100px",
              top: scrolled && "-10px",
            }}
          />
        </div>
        <a
          href={redirect}
          style={{
            zIndex: !open && "9999",
            paddingTop: isMobile ? "20px" : "0px",
          }}
        >
          <button
            id="btn1"
            data-aos="fade-up"
            data-aos-offset="50"
            style={{
              // margin: scrolled && "0px",
              fontSize: scrolled && !isMobile && "11px",
              width: scrolled && "",
              // marginRight: isMobile && "12px",
            }}
          >
            Member Area
          </button>
        </a>
      </header> */}

      <GoogleTranslate />

      <link rel="stylesheet" href={require("../theme/home.css")} />
      <link rel="stylesheet" href={require("../theme/animate.css")} />
      <link rel="stylesheet" href={require("../theme/bootstrap.min.css")} />
      <link rel="stylesheet" href={require("../theme/hover-min.css")} />
      <link rel="stylesheet" href={require("../theme/slick.css")} />
      <link rel="stylesheet" href={require("../theme/slick-theme.css")} />
      <link rel="stylesheet" href={require("../theme/style.css")} />

      <header
        class="header-main"
        style={{
          marginBottom: "50px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: isScrolled ? "#01041e" : "transparent",
          marginTop: isScrolled ? "0px" : "40px",
        }}
      >
        <div class="container" style={{ maxWidth: "85%" }}>
          <div class="row">
            <div
              class="col-lg-6 col-4"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                class="header-logo"
                onClick={() => navigate("/")}
                style={{ cursor: "pointer" }}
              >
                <img src={require("../assets/images/home/logo.png")} alt="" />
              </div>
              {!mobile && (
                <p
                  style={{
                    marginLeft: "15px",
                    fontSize: largest ? "20px" : "18px",
                    color: "#1650C4",
                    fontFamily: "batangas",
                  }}
                >
                  PROFESSIONAL LOGISTICS NETWORK
                </p>
              )}
            </div>
            <div
              class="col-lg-6 col-8"
              style={{ marginBottom: isMenuOpen ? "20px" : "0px" }}
            >
              <div class="header-nav">
                <div class="header-btn">
                  <a
                    href="#"
                    class="themeBtn"
                    onClick={() => (window.location.href = redirect)}
                  >
                    Member Area
                  </a>
                </div>
                <div class="side-menu">
                  <div id="menu" onClick={toggleMenu}>
                    <div
                      className={`menu-line1 ${isMenuOpen ? "rotate1" : ""}`}
                    ></div>
                    <div
                      className={`menu-line2 ${isMenuOpen ? "rotate2" : ""}`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {isMenuOpen && (
        <>
          <div className={isMenuOpen ? "nav-page1 transform" : "nav-page1"}>
            <div class="res_menu_Nav">
              <div class="side-menu" style={{ marginTop: "10px" }}>
                <div id="menu" onClick={toggleMenu}>
                  <div
                    className={`menu-line1 ${isMenuOpen ? "rotate1" : ""}`}
                  ></div>
                  <div
                    className={`menu-line2 ${isMenuOpen ? "rotate2" : ""}`}
                  ></div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "50px",
                  margin: "20px",
                }}
              >
                <div style={{ flexDirection: "column", display: "flex" }}>
                  <a href="#" onClick={() => navigate("/about")}>
                    About
                  </a>
                  <a href="#" onClick={() => navigate("/pro-advantage")}>
                    PRO-Advantages
                  </a>
                  <a href="#" onClick={() => navigate("/team")}>
                    Our Team
                  </a>
                  <a href="#" onClick={() => navigate("/gallery")}>
                    Gallery
                  </a>
                  <a href="#" onClick={() => navigate("/events")}>
                    Upcoming Events
                  </a>
                  <a href="#" onClick={() => navigate("/request-demo")}>
                    Request a Demo
                  </a>
                  <a href="#" onClick={() => navigate("/testimonial")}>
                    Testimonials
                  </a>
                  <a href="#" onClick={() => navigate("/contact-us")}>
                    Contact Us
                  </a>
                </div>
                <div style={{ flexDirection: "column", display: "flex" }}></div>
              </div>
            </div>
            <div class="navigation_menu">
              <a href="#" onClick={() => navigate("/about")}>
                About
              </a>
              <a href="#" onClick={() => navigate("/pro-advantage")}>
                Pro-Advantages
              </a>
              <a href="#" onClick={() => navigate("/team")}>
                Our Team
              </a>
              <a href="#" onClick={() => navigate("/gallery")}>
                Gallery
              </a>
            </div>
          </div>
          <div className={isMenuOpen ? "nav-page2 transform" : "nav-page2"}>
            <div class="navigation_menu">
              <a href="#" onClick={() => navigate("/events")}>
                Upcoming Events
              </a>

              <a href="#" onClick={() => navigate("/request-demo")}>
                Request a Demo
              </a>
              <a href="#" onClick={() => navigate("/testimonial")}>
                Testimonials
              </a>

              <a href="#" onClick={() => navigate("/contact-us")}>
                Contact Us
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
